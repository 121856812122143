import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./page/home/Home";
import axios from "axios";
import Participants from "./page/participants/Participants";


axios.defaults.baseURL = "https://api-survey.notionsgroup.com/api/v1";
 // axios.defaults.baseURL = "http://localhost:9877/api/v1";
axios.defaults.withCredentials = true;

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/users" element={<Participants />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
