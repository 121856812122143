import React, { useState, useEffect } from "react";
import axios from "axios";
import "./home.scss";
import countries from "./countries.js";

const Home = () => {
  const [destination, setDestination] = useState(""); // This state controls the input value
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showError, setShowError] = useState(false);
  const [reasonToBuy, setReasonToBuy] = useState("");
  const [resident, setResident] = useState("");
  const [age, setAge] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState({ lat: null, lng: null });
  const [error, setError] = useState(null);
  const [gender, setGender] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");
  const [language, setLanguage] = useState("en"); // default language
  const [ip, setIp] = useState("");
  const [brand, setBrand] = useState("");
  const [otherBrand, setOtherBrand] = useState("");

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (!isValidEmail(newEmail) && newEmail !== "") {
      setError("Invalid email format");
    } else {
      setError("");
    }
  };

  // console.log("otherBrand", otherBrand);

  useEffect(() => {
    // Fetch user IP and approximate location
    axios
      .get("https://ipinfo.io/json?token=6bebd53a7455c1", {
        withCredentials: false,
      }) // Replace with your token from ipinfo.io
      .then((response) => {
        const userIp = response.data.ip;
        setIp(userIp);

        // Extract latitude and longitude
        const [lat, lng] = response.data.loc.split(",");
        setLocation({ lat: parseFloat(lat), lng: parseFloat(lng) });
      })
      .catch((error) => console.error("IP-based location error:", error));
  }, []);

  // useEffect(() => {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setLocation({
  //           lat: position.coords.latitude,
  //           lng: position.coords.longitude,
  //         });
  //       },
  //       (error) => {
  //         switch (error.code) {
  //           case error.PERMISSION_DENIED:
  //             setError(
  //               "Location access was denied. Please enable it in your settings."
  //             );
  //             break;
  //           case error.POSITION_UNAVAILABLE:
  //             setError("Location information is unavailable. Try again later.");
  //             break;
  //           case error.TIMEOUT:
  //             setError(
  //               "The request to get user location timed out. Please refresh the page."
  //             );
  //             break;
  //           default:
  //             setError("An unknown error occurred while fetching location.");
  //         }
  //       }
  //     );
  //   } else {
  //     setError("Geolocation is not supported by your browser.");
  //   }
  // }, [ip]);

  const translations = {
    en: {
      selectAgeGroup: "Select Age Group",
      selectGender: "Select Gender",
      nationality: "Nationality",
      residency: "Residency",
      reasonToBuy: "Reason to Buy",
      suggestion: "Suggestion",
      emailPlaceholder: "Email for receiving gift cards (Optional)",
      submit: "Submit",
      errorMsg: "Please enter a Nationality.",
      allFieldsRequired: "All fields need to be filled",
      successMsg: "Successfully submitted!",
      failureMsg: "Failed to submit. Please try again.",
    },
    ar: {
      selectAgeGroup: "حدد الفئة العمرية",
      selectGender: "اختر الجنس",
      nationality: "الجنسية",
      residency: "الإقامة",
      reasonToBuy: "سبب الشراء",
      suggestion: "اقتراح",
      emailPlaceholder: "البريد الإلكتروني لاستلام بطاقات الهدايا (اختياري)",
      submit: "إرسال",
      errorMsg: "يرجى إدخال الجنسية.",
      allFieldsRequired: "يجب ملء جميع الحقول",
      successMsg: "تم الإرسال بنجاح!",
      failureMsg: "فشل في الإرسال. يرجى المحاولة مرة أخرى.",
    },
    ja: {
      selectAgeGroup: "年齢層を選択",
      selectGender: "性別を選択",
      nationality: "国籍",
      residency: "居住地",
      reasonToBuy: "購入理由",
      suggestion: "提案",
      emailPlaceholder: "ギフトカードを受け取るためのメール（オプション）",
      submit: "送信",
      errorMsg: "国籍を入力してください。",
      allFieldsRequired: "すべてのフィールドに入力してください",
      successMsg: "正常に送信されました!",
      failureMsg: "送信に失敗しました。もう一度試してください。",
    },
    ko: {
      selectAgeGroup: "연령대 선택",
      selectGender: "성별 선택",
      nationality: "국적",
      residency: "거주지",
      reasonToBuy: "구매 이유",
      suggestion: "제안",
      emailPlaceholder: "기프트 카드를 받기 위한 이메일 (선택 사항)",
      submit: "제출",
      errorMsg: "국적을 입력해주세요.",
      allFieldsRequired: "모든 필드를 채워야 합니다.",
      successMsg: "성공적으로 제출되었습니다!",
      failureMsg: "제출 실패. 다시 시도해주세요.",
    },
    hi: {
      selectAgeGroup: "आयु समूह चुनें",
      selectGender: "लिंग चुनें",
      nationality: "राष्ट्रीयता",
      residency: "निवास",
      reasonToBuy: "खरीदने का कारण",
      suggestion: "सुझाव",
      emailPlaceholder: "गिफ्ट कार्ड प्राप्त करने के लिए ईमेल (वैकल्पिक)",
      submit: "सबमिट करें",
      errorMsg: "कृपया राष्ट्रीयता दर्ज करें।",
      allFieldsRequired: "सभी फ़ील्ड भरने की आवश्यकता है",
      successMsg: "सफलतापूर्वक प्रस्तुत किया गया!",
      failureMsg: "प्रस्तुत करने में विफल। कृपया फिर से प्रयास करें।",
    },
    ru: {
      selectAgeGroup: "Выберите возрастную группу",
      selectGender: "Выберите пол",
      nationality: "Национальность",
      residency: "Место жительства",
      reasonToBuy: "Причина покупки",
      suggestion: "Предложение",
      emailPlaceholder:
        "Электронная почта для получения подарочных карт (необязательно)",
      submit: "Отправить",
      errorMsg: "Пожалуйста, введите национальность.",
      allFieldsRequired: "Необходимо заполнить все поля",
      successMsg: "Успешно отправлено!",
      failureMsg: "Не удалось отправить. Пожалуйста, попробуйте снова.",
    },
    zh: {
      selectAgeGroup: "选择年龄组",
      selectGender: "选择性别",
      nationality: "国籍",
      residency: "居住地",
      reasonToBuy: "购买理由",
      suggestion: "建议",
      emailPlaceholder: "接收礼品卡的电子邮件（可选）",
      submit: "提交",
      errorMsg: "请输入国籍。",
      allFieldsRequired: "所有字段都需要填写",
      successMsg: "成功提交!",
      failureMsg: "提交失败，请重试。",
    },
  };

  const translate = (key) => {
    return translations[language][key] || key;
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    // Validate required fields
    if (!destination || !resident || !age || !gender || !reasonToBuy) {
      setError(translate("allFieldsRequired"));
      setLoading(false);
      return;
    }

    const data = {
      destination,
      reasonToBuy,
      resident,
      age,
      suggestion,
      location,
      gender,
      email,
      ip,
      brand: brand === "Other" ? otherBrand : brand,
      language,
    };

    try {
      const response = await axios.post("/send-data", data);
      setResponse(translate("successMsg"));

      // Reset form fields after successful submission
      setDestination("");
      setReasonToBuy("");
      setResident("");
      setAge("");
      setSuggestion("");
      setEmail("");
      setGender("");
      setShowDropdown(false);
      setShowError(false);
      setFilteredCountries([]);
      setBrand("");
      setOtherBrand("");

      setTimeout(() => setResponse(""), 3000);
    } catch (error) {
      setError(translate("failureMsg"));
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setDestination(value);
    setShowError(false);
    if (value) {
      const filtered = countries.filter((country) =>
        country.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCountries(filtered);
      setShowDropdown(true);
    } else {
      setFilteredCountries([]);
      setShowDropdown(false);
    }
  };

  const handleSelect = (country) => {
    setDestination(country);
    setFilteredCountries([]);
    setShowDropdown(false);
  };

  return (
    <div className="__fw">
      <div className="__mw">
        <form onSubmit={submitForm}>
          <div className="home">
            <div className="heading">
              <img src={require("../../img/logo.webp")} alt="" />
            </div>

            {/* Language Selector */}
            <div className="age">
              <select
                onChange={(e) => setLanguage(e.target.value)}
                value={language}
                className="select-dropdown"
              >
                <option value="" disabled hidden>
                  Select Language
                </option>
                <option value="en">English</option>
                <option value="ar">Arabic</option>
                <option value="ja">Japanese</option>
                <option value="ko">Korean</option>
                <option value="hi">Hindi</option>
                <option value="ru">Russian</option>
                <option value="zh">Chinese</option>
              </select>
            </div>
            {/* <div className="age">
              <select
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
                className="select-dropdown"
              >
                <option value="" style={{ color: "grey" }}>
                  Select Brand
                </option>
                <option value="Chocodate">Chocodate</option>
                <option value="Arabian Delights">Arabian Delights</option>
                <option value="Other">Other</option>
              </select>
            </div>
            {brand === "Other" ? (
              <div className="email_">
                <input
                  type="text"
                  value={otherBrand} // This binds the email field with the state value
                  placeholder="Write brand name here"
                  onChange={(e) => setOtherBrand(e.target.value)} // This updates email state
                />
              </div>
            ) : null} */}

            <div className="age">
              <select
                value={age}
                onChange={(e) => setAge(e.target.value)}
                className="select-dropdown"
              >
                <option value="" style={{ color: "grey" }}>
                  {translate("selectAgeGroup")}
                </option>
                <option value="10-20">10-20</option>
                <option value="21-30">21-30</option>
                <option value="31-40">31-40</option>
                <option value="41-50">41-50</option>
                <option value="51-60">51-60</option>
                <option value="61-70">61-70</option>
                <option value="70+">70+</option>
              </select>
            </div>

            <div className="age">
              <select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                className="select-dropdown"
              >
                <option value="" style={{ color: "grey" }}>
                  {translate("selectGender")}
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>

            <div className="age">
              <div className="search_main_wrp">
                <div className="search_in">
                  <input
                    type="text"
                    value={destination} // This binds the input field with the state value
                    onChange={handleChange} // This updates the value dynamically
                    placeholder={translate("nationality")}
                  />
                  {showDropdown && filteredCountries.length > 0 && (
                    <div className="country_list">
                      <ul>
                        {filteredCountries.slice(0, 5).map((country, index) => (
                          <li key={index} onClick={() => handleSelect(country)}>
                            {country}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                {showError && (
                  <div className="error_msg">
                    <small className="msg_text">{translate("errorMsg")}</small>
                  </div>
                )}
              </div>
            </div>

            <div className="age">
              <select
                value={resident}
                onChange={(e) => setResident(e.target.value)}
                className="select-dropdown"
              >
                <option value="" style={{ color: "grey" }}>
                  {translate("residency")}
                </option>
                <option value="UAE Resident">UAE Resident</option>
                <option value="Tourist">Tourist</option>
              </select>
            </div>

            <div className="age">
              <select
                value={reasonToBuy}
                onChange={(e) => setReasonToBuy(e.target.value)}
                className="select-dropdown"
              >
                <option value="" style={{ color: "grey" }}>
                  {translate("reasonToBuy")}
                </option>
                <option value="Personal Consumption">
                  Personal Consumption
                </option>
                <option value="Gifting">Gifting</option>
              </select>
            </div>

            <div className="age">
              <div className="text_area">
                <textarea
                  name=""
                  rows={3}
                  placeholder={translate("suggestion")}
                  value={suggestion} // This binds the textarea value with the state
                  onChange={(e) => setSuggestion(e.target.value)} // This dynamically updates the suggestion value
                ></textarea>
              </div>
            </div>

            <div className="email_">
              <input
                type="text"
                value={email}
                placeholder={translate("emailPlaceholder")}
                onChange={handleEmailChange}
              />
            </div>

            <button type="submit" className="button" >
              <div className="submit_btn">
                {loading ? (
                  <span>Please wait...</span>
                ) : (
                  <span>{translate("submit")}</span>
                )}
              </div>
            </button>

            <div className="error_wrp">
              {response && (
                <small style={{ color: "#0db776" }}>{response}</small>
              )}
              {error && <small style={{ color: "red" }}>{error}</small>}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Home;
