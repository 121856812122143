import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import "./participants.scss";

const Participants = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const userData = async () => {
      try {
        const response = await axios.get(`/survey-users`);
        setUsers(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    userData();
  }, []);

  console.log(users);

  const columns = [
    { field: "age", headerName: "Age Group" },
    {
      field: "gender",
      headerName: "Gender",
    },
    { field: "destination", headerName: "Nationality" },
    { field: "email", headerName: "Email", width: 250 },
    { field: "resident", headerName: "Resident" },
    { field: "reasonToBuy", headerName: "Reason To Buy" },
    { field: "suggestion", headerName: "Suggestion", width: 300 },
    // { field: "ip", headerName: "IP" },
    // {
    //   field: "location",
    //   headerName: "Location",
    //   width: 200,
    //   valueGetter: (params) => {
    //     if (!params || !params.lat || !params.lng) return "N/A"; // Directly check lat & lng
    //     return `${params.lat}, ${params.lng}`;
    //   },
    // },
  ];

  return (
    <div className="__fw">
      <div className="__mw">
        <div className="pt_grid">
          {loading ? (
            <div className="loading">
              <p>Loading....</p>
            </div>
          ) : (
            <div className="data_gird">
              <DataGrid
                key={users._id }
                //onCellClick={handleCellClick}
                rows={users}
                columns={columns}
                pageSize={5}
                disableRowSelectionOnClick
                initialState={{
                  pagination: {
                    paginationModel: {
                      // pageSize: 5,
                    },
                  },
                }}
                getRowId={(row) => row._id}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Participants;
